import { Injectable } from '@angular/core';

import { isPlatform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class PlatformService {
    constructor() {}

    // android device only
    public get isAndroid(): boolean {
        return isPlatform('android');
    }

    // ios device only
    public get isIos(): boolean {
        return isPlatform('ios');
    }

    // native app on device
    public get isNativeDevice(): boolean {
        return (
            (isPlatform('ios') || isPlatform('android')) &&
            !isPlatform('mobileweb')
        );
    }

    public get isLargeVertical(): boolean {
        if ((window.innerWidth <= window.innerHeight) && (window.innerWidth > 991)) {
            return true;
        } else {
            return false;
        }
    }

    // web browser on any device
    public get isBrowser(): boolean {
        return isPlatform('desktop') || isPlatform('mobileweb');
    }

    // web browser on non-device only
    public get isDesktopBrowser(): boolean {
        return isPlatform('desktop') && !isPlatform('mobileweb');
    }

    // web browser on device only
    public get isMobileBrowser(): boolean {
        return !isPlatform('desktop') && isPlatform('mobileweb');
    }
}
